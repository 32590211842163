import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import '../diigo/css/style.css';


export default function HomePage() {
    const history = useHistory();

    useEffect(() => {
        
    }, []);
    
    

    return (
        <div>
            <header>
                <div  className="head_top">
                    <div className="header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col logo_section">
                                <div className="full">
                                <div className="center-desk">
                                    <div className="logo">
                                        <a href="#"><img src="assets/img/home/logo.png" alt="#" /></a>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 nav_col">
                                <nav className="navigation navbar navbar-expand-md navbar-dark ">
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarsExample04">
                                    <ul className="navbar-nav mr-auto">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#"> Home  </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link"  href="#" onClick={()=>{history.push(`/hm_login`)}}>Login</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link"  href="#" onClick={()=>{history.push(`/privacy`)}}>Privacy Policy</a>
                                        </li>
                                    </ul>
                                </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                    </div>
                    <section className="banner_main">
                    <div className="container-fluid">
                        <div className="row d_flex">
                            <div className="col-md-6">
                                <div className="text-bg">
                                <h1>Christian Encouragement and Prayer at your finger tips!</h1>
                                <p>No problem is to hard to hear. "He Hears You" is an online self aid app designed to help you by connecting you with Christian Guides and Prayer Partners</p>
                                <a href="#book">Read More</a>
                                </div>
                            </div>
                            <div className="col-md-6 phone-img">
                                <div className="text-img">
                                <figure><img src="assets/img/home/apphome.png" alt="#"/></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    </section>
                </div>
            </header>
            
            <div id="book" className="business">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <div className="titlepage">
                            <span>Book Your Appointments Online</span>
                            <h2>In 4  Simple Steps</h2>
                            <p>You can book an appointment with a helper in just 4 small steps. Get connected now!</p>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="business_box ">
                                <figure><img src="assets/img/home/meeting1.png" alt="#"/></figure>
                                <p>
                                    <b>Step 1: State Your Issue</b><br/>
                                    This is a safe place for you to be heard. Tell your helper all that you are going through.<br/><br/>

                                    <b>Step 2: Select an issue category</b><br/>
                                    Select the category which best describes what you are experiencing.<br/><br/>

                                    <b>Step 3: Select a time for your Appointment</b><br/>
                                    Are you working around a tight schedule? No problem select a time is best suitable for you.<br/><br/>

                                    <b>Step 4: Select a helper</b><br/>
                                    We have a wide range of helpers who specialize in different areas. 
                                    Select the helper who you are most comfortable with.<br/><br/>
                                </p>
                                <a className="read_more" href="#pray">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <div id="pray" className="section">
                <div className="container">
                    <div id="" className="Testimonial Testimonial2">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="titlepage">
                                <h2>Pray With Me</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="Testimonial_box">
                                <i><img src="assets/img/home/prayer.png" alt="#"/></i>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="Testimonial_box">
                                <h4>"And this is the confidence that we have in him, that, if we ask any thing according to his will, he heareth us" ~  1 John 5:14</h4>
                                <p><b>Pray With Me:</b> Do you need prayer, or do you just want to talk with someone? well this is the perfect place for you. 
                                'Pray With Me' is a special feature on the app that allows you to connect with prayer group members and receive special prayer based on your situation. 
                                     </p>
                                
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            
            <div className="projects">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <div className="titlepage">
                            <span>Consult With</span>
                            <h2>Christian Guides</h2>
                            <p>"He Hears You" is an online self aid app designed to help you by <br/> connecting you with Christian Guides and Prayer Partners</p>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="projects_box ">
                                <figure><img src="assets/img/home/christian_counselling.png" alt="#"/></figure>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="projects_box ">
                                <p>"He Hears You" is an online self aid app designed to help you by connecting you with Christian Guides and Prayer Partners</p>
                                <a className="read_more" href="#">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            
            <div className="section freely-communicate">
                <div className="container">
                    <div id="" className="Testimonial">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="titlepage">
                                <h2>Freely Communicate</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="Testimonial_box">
                                <i><img src="assets/img/home/communicate.png" alt="#"/></i>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="Testimonial_box">
                                <h4>Anyway. Anywhere. Anytime</h4>
                                <p><b>Quick Chat:</b> You can also communicate with helpers instantly!  If you need urgent help and you do not want to go through the process of Scheduling an appoinment,
                                     You can connect with a helper in real time through our 'Quick Chat' feature.
                                     </p>
                                
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            
            
            {/* <div id="contact" className="contact">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <div className="titlepage">
                            <h2>Contact us</h2>
                            <span>Do you have any questions please feel free to reach us</span>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                    <div className="col-md-12 ">
                        <form className="main_form ">
                            <div className="row">
                                <div className="col-md-12 ">
                                <input className="form_contril" placeholder="Name " type="text" name="Name "/>
                                </div>
                                <div className="col-md-12">
                                <input className="form_contril" placeholder="Phone Number" type="text" name=" Phone Number"/>
                                </div>
                                <div className="col-md-12">
                                <input className="form_contril" placeholder="Email" type="text" name="Email"/>
                                </div>
                                <div className="col-md-12">
                                <textarea className="textarea" placeholder="Message" type="text" name="Message"></textarea>
                                </div>
                                <div className="col-sm-12">
                                <button className="send_btn">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
    
}